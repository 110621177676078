

/* .App-logo {
  height: 10vh;
  pointer-events: none;
  size: 10vh;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

@font-face {
  font-family: "bubbleValentine";
  src: url("../assets/fonts/BubbleValentine.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "delaGothic";
  src: url("../assets/fonts/DelaGothicOne-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "coolvetica";
  src: url("../assets/fonts/coolveticaRg.otf");
  font-weight: normal;
  font-style: normal;
  size-adjust: 110%;
}

.header {
  text-align: center;
  background-color: #000000;
  min-height: 20%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
  top: 0;
  margin-bottom: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  border-bottom: #f1f3df solid 2px;
  transition: background-color 0.4s ease, background-image 0.7s ease;
}
.header.default{
  background-color: #000000;
  background-image: linear-gradient(170deg, #000000 40%, #209ad7);

}

.header.draco {
  background-color: #000000;
  background-image: linear-gradient(170deg, #000000 40%, #bf75f0);
}

.header.mukk {
  background-color: #000000;
  background-image: linear-gradient(170deg, #000000 40%, #ff6666);
}

.header.melk {
  background-color: #000000;
  background-image: linear-gradient(170deg, #000000 40%, #ffa366);
}

.header.charm {
  background-color: #000000;
  background-image: linear-gradient(170deg, #000000 40%, #ff99eb);
}

.header.amigo {
  background-color: #000000;
  background-image: linear-gradient(170deg, #000000 40%, #00cc99);
}

.header.septic {
  background-color: #000000;
  background-image: linear-gradient(170deg, #000000 40%, #8266ff);
}

.header.err404 {
  background-color: #000000;
  background-image: none;
}

/* 
.header #my-links{
  display: none;
} */

/* .App-link {
  color: #61dafb;
} */
.header-logo{
  width: 100%;
}

.footer {
  background-color: #000000;
  min-height: 5%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
   align-items: center;
  /*justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  /* position: sticky; */
  bottom: 0;
  padding: 10px;
  position: static;
  font-size: 15px;
  z-index: 1;
  font-family: "coolvetica";
  border-top: #f1f3df solid 2px;
}

.footer-text {
  text-align: left;
}

.footer-icon {
  position: relative;
  width: 25px;
  float: initial;
  filter: invert(100%);
  margin: 15px;
  margin-bottom: 5px;
  transition: ease .1s;
} 
.footer-icon:hover{
  scale: 115%;
}
/* 
.button-image{
  width: 11%;
  position: relative;
  z-index: 2;
  
} */
.header-transition{
  background-color: #00000000;
  background-size: cover;
  z-index: 2;
  width: 100%;
  height: 100%;
  transition: .6s ease;
}

.header-transition.active1{
  background-color: black;
}

@keyframes header-fade{
  from {background-color: #00000000;}
  to {background-color: black;}
  to {background-color: #00000000;}
}

.header-buttons {
  margin: 5px;
  background-color: #2843fa;
  border: 2px solid #f1f3df;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 35px;
  border-top-left-radius: 5px;
  text-align: center !important;
  color: white;
  font-size: 30px;
  width: 11%;
  height: 100%;
  min-width: 11%;
  min-height: 70px;
  font-family: "delaGothic";
  text-align: center;
  transition: .3s ease;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  overflow: hidden;
}

.header-buttons:hover{
  scale: 95%;
  cursor: pointer;
  background-color: #fbd644;
 
background-repeat: no-repeat;
background-size: cover;
}

#draco:hover{
   background-image: url("https://storage.cloud.google.com/neo-pyrite-site/Icons/DracoAvi.jpg");
   
}

#mukk:hover{
  background-image: url("https://storage.cloud.google.com/neo-pyrite-site/Icons/MukkAvi.jpg");
}

#melk:hover{
  background-image: url("https://storage.cloud.google.com/neo-pyrite-site/Icons/MelkAvi.jpg");
}

#charm:hover{
  background-image: url("https://storage.cloud.google.com/neo-pyrite-site/Icons/CharmAvi.jpg");
}

#amigo:hover{
  background-image: url("https://storage.cloud.google.com/neo-pyrite-site/Icons/AmigoAvi.jpg");
}

#septic:hover{
  background-image: url("https://storage.cloud.google.com/neo-pyrite-site/Icons/SepticAvi.jpg");
}

#draco:hover,#mukk:hover,#melk:hover,#charm:hover,#amigo:hover,#septic:hover{
  background-color: #2843fa;
}

.header-buttons.current{
  background-color: #0022ff;
  border-color: #fbd644;
  color: #fbd644;
}


.button-wrap{
  position: static;
  background-color: #2843fa;
  height: 100%;
  width: 100%;
  transition:.5s ease;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  text-align: center;
}

.button-wrap:hover{
  background-color: #2844fa00;
  color: #00000000;
  /* content-visibility: hidden; */
}



.static-background {
  position:fixed;
  top: 0;
  left:0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}

.mobile-only{
  display: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0 auto;
}



.page-background {
  background-color: #00000000;
  min-height: 90vh;
  background-size: cover;
  
  margin: 0;
  padding: 0;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 0%;
  position: relative;
  z-index: 0;
  border-left: #0000003d groove 25px;
  border-right: #0000003d ridge 25px;
  transition: .5s ease;
  transition-delay: .2s;
}




.content-container {
  margin-left: 1%;
  margin-right: 1%;
  padding-top: 2%;
  
  text-align: center;
  align-content: center;
  display: flex;
  position: relative;
  float: none;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "coolvetica";
  transition: ease-out .2s;
}

.content-container.inactive1{
  opacity: 0%;
}


.container-row {
  display:table;
        clear:both;
        width:100%;
        content:"";
        margin-bottom: 20px;
}

.container-row.headera{
  margin-top: 15px;
}

.container-col-left{
  width: 40%;
float:left;
}

.container-col-right{
  width: 60%;
  float:left;
  display: flex;
  flex-direction: column;
}

.header-col-left{
width: 15%;
height: auto;
float: left;
align-content: center;
justify-content: center;
padding: 5px;

}

/* .twitter-container{
  
} */

.header-col-right{
  width:85%;
  height:20vh;
  align-content: center;
  float:left;
  margin-bottom: 10px;
}


.component-container{
  margin: 10px;
  padding: 2%;
  background-color: #ffffff;
  width: 98%;
  height: min-content;
  position: relative;
  overflow: hidden;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 20px;
  border-color: #0000002f;
  border-width: 3px;
  border-style: solid;
  transition: ease .1s;
  color: white;
}


.component-container.alt{
  
  background-color:#00000000;
  border-width:0px;
  padding-top:60px;
  padding-bottom:60px;
  padding-left:60px;
  overflow:visible;
  justify-content:center;

}

.component-container.yt{
  min-height: 30vw;
}

@media screen and (max-width: 1024px) {
  /* .about-container{
    width: 85% !important;
  } */

  .yt-vid-container{
    width: 90vw !important;
    height: 50.625vw !important;
  }
  

  .footer{
    align-items: center;
  }
  .footer-icon{
    margin-right: 15px;
    
    scale: 120%;
  }
  .container-row{
    width:100%;
  }
  .container-row.headera{
    margin: 0px;
  }
  .container-col-left {
    width: 100% !important;
  }
  .container-col-right {
    width: 100% !important;
    margin-top: 0;
    flex-direction: column-reverse;
  }
  .component-container {
    width: 100%; /* Stack containers on smaller screens */
    margin: 2% 0; /* Adjust margin for stacked layout */
  }
  .page-background{
    background-color: #00000000 !important;
    border-left: 0px;
    border-right: 0px;
    min-height: 1000px;
  }
#err-page{
  margin-left: 0;
  margin-right: 0;
}


  #err404{
    background-color: #FFFFFFCC;
    margin-left: 0;
    margin-right: 0;
    padding: 10px;
    min-height: 1000px;
  }
  .err2{
    font-size: 25px !important;
  }

  .header-buttons{
    width: 45%;
    margin: 2%;
    
  }
  .header-empty{
    cursor: initial !important;


  }

  .header{
    position:static;
    z-index: 2;
    background-image: none !important;
  
  }

  .header.menu-active{
    z-index: 5;
    background-size: cover;
    height: 100%;
  }

  .header-buttons-cont {
    
    flex-direction: column;
  }
  .mobile-only{
    display: block;
    width: 50px;
    
  }
  .header-logo{
    width: 45%;
    
  }
  .header-col-left{
    width:100%;
    height: auto;
    
  }
  .header-col-right{
    width: 100%;
    height: auto;
  }
  .button-wrap:hover{
    background-color: #2843fa;
    color:#f1f3df;
    background-size: cover;
  }
  #draco:hover,#mukk:hover,#melk:hover,#charm:hover,#amigo:hover,#septic:hover{
    background-image: none;
  }
  .component-container.yt{
    height: 70vw;
  }
  .component-container.alt2{
    margin-top: 0%;
    margin-bottom: 0%;
    font-size: 18px !important;
  }
  .player-name{
    font-size: 60px !important;
    
  }

  .lil-squid{
    display: none;
    
  }
  .shirt-img{
    width: 25% !important;
  }
  .home-page-blurb{
    font-size: 15px !important;
  }
 
}
.bio-icon{
  width: 60%;
  height: auto;
  position: relative;
  border-radius: 20px;
  box-shadow: 10px 10px 20px #f1f3dfad;
  z-index: 4;
}
.shirt-img{
  width: 45%;
  margin: 1%;
  transition: .1s ease;
  border-radius: 5px;
}
.shirt-img:hover{
  scale: 105%;
}


.icon-splash{
  width: 75%;
  height: auto;
  position:absolute;
  border-radius: 20px;
  z-index: 3;
  left: 0; 
  /* top: 0; */
}

.player-name{
  font-size:90px;
  text-transform: uppercase;
  font-family:"bubbleValentine";
  position: relative;
    z-index: 10;
}

.lil-squid{
  position: relative;
  opacity: 80%;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
    z-index:9;
}


.video-container {
  
  position: relative;
  width: 100%;
  max-width: 1280px;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 20px;
}
.footer-icon-container{
    display: flex;
    margin-bottom: 5px;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    
  }
.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform .5s ease-in-out;
  object-fit: cover;
  transform: translateX(100%); /* Start offscreen to the right */

}

.video-element.active {
  transform: translateX(0); /* Slide into view */
}

.video-element.exit {

  transform: translateX(-100%); /* Slide out to the left */
}

.weapon-container{
  background-color:#ffffff21 !important;
  display: inline-flex;
  flex-direction: row;
  
  margin: 2px;
  margin-left: 10px;
  margin-right: 10px;
  border-color: #000000a2;
  border-radius: 8px;
  border-width: 0px;
  border-style: solid;
  padding: 2px;
  transition: ease .5s;
}

.weapon-container.clip{
  background-color: #ffffff3d !important;
  height: 20px;
  width: 20px;
  margin: 3px;
  border-radius: 12px;

}

.weapon-container.clip:hover{
  cursor: pointer;

}

.weapon-container.clip.current{
  background-color: #ffffff !important;
}

.weapon-container.socials{
  padding-left: 8px;
  padding-right: 8px;
}

.socials-icon{
  width: 45px;
  margin: 5px;
  filter: invert(100%);
  transition: ease .2s;
}

.socials-icon:hover{
    
    scale: 105%;
}

.yt-vid-container{
  width: 95vh;
  height: 53.4375vh;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 30px;
}

.twitter-container{
  height: 100%;
  width: 100%;
  padding: 0%;
}

.err1{font-size: 90px;}

.err2{
  font-size: 50px;
  font-style: italic;
}

.about-container{
  width: 95%;
  position: relative;
  z-index: 7;
  display: inline-flex;
  flex-direction: row;
  color: white;
  margin: 2px;
  margin-left: 10px;
  margin-right: 10px;
  border-color: #ffffff65;
  border-radius: 8px;
  border-width: 4px;
  border-style: solid;
  padding: 2px;
  transition: ease .5s;
  overflow: hidden;
  cursor: pointer;
}

.about-container:hover{
  scale: 95%;
  color: black;
  .about-icon{
    filter: invert(0%);
  }
  /* filter: invert(100%); */

}

.about-icon{
  height: 100%;
  justify-content: right;
  float: right;
  filter: invert(100%);
  margin-right: 5px;
  margin-left: 15px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transition: ease .5s;

}